.search-container .tabs {
  margin-bottom: 10px;
}

body.darkmode .search-container a,
.search-container a {
  color: inherit;
}

body.darkmode .search-container a:hover,
.search-container a:hover {
  color: inherit;
}

.search-container .tabs ul {
  display: flex;
  align-items: center;
}

.search-container .tabs span {
  display: inline-block;
  border-radius: 4px;
  padding: 7px 15px 8px;
  cursor: pointer;
  margin-left: 5px;
}

.search-container .tabs a:first-child span {
  margin-left: 0px;
}

.search-container .tabs span:hover:not(.active) {
  background-color: #e4e7ec;
}

.search-box {
  background-color: #fff;
  border: 1px solid var(--light-boder-color);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  transition: background-color 0.2s border 0.2;

  position: relative;
}

.search-box.info {
  margin-bottom: 50px;
}

body.darkmode .search-box {
  background-color: #0e1625;
  border: 1px solid var(--dark-boder-color);
  transition: background-color 0.2s border 0.2;
}

.search-box .search-box-top {
  border-bottom: 1px solid var(--light-boder-color);
  padding: 15px 20px 20px;
  transition: border-bottom 0.2;
}

body.darkmode .search-box .search-box-top {
  border-bottom: 1px solid var(--dark-boder-color);
  transition: border-bottom 0.2;
}

.search-box .search-box-top {
  border-bottom: 1px solid var(--light-boder-color);
  padding: 15px 20px 20px;
  position: relative;
}

.search-box .search-box-top .loader {
  position: absolute;
  bottom: 0px;
  left: 0%;
  right: 100%;
  height: 2px;
  background-color: #5d91d9;
  animation: example 5s infinite;
}

@keyframes example {
  0% {
    left: 0%;
    right: 100%;
  }
  25% {
    left: 0%;
    right: 0%;
  }
  50% {
    left: 100%;
    right: 0%;
  }
  75% {
    right: 0%;
    left: 0%;
  }
  100% {
    left: 0%;
    right: 100%;
  }
}

.search-box .search-box-top form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.search-box-top .textfield-container {
  flex-grow: 1;
}

.search-box-top .textfield-container label {
  display: flex;
  flex-flow: column;
}

.search-box-top .textfield-container .label {
  font-size: 14px;
  color: #a4adbb;
}

.search-box-top .textfield-container input {
  border: 0px;
  font-size: 18px;
  color: #51565d;
  background: transparent;
}

.search-box-top .textfield-container input:focus {
  border: 0;
  outline: 0;
}

.search-box-top .search-right-side {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
}

.search-box-top .search-right-side .status {
  margin-right: 7px;
  margin-bottom: -10px;
  font-size: 30px;
  color: #fff;
  opacity: 1;
  animation: fadeIn 1s ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.search-box-top .search-right-side .status.success {
  color: #9dd49a;
}

.search-box-top .search-right-side .status.error {
  color: #d95d69;
}

.search-box-top .search-right-side .submit {
  display: inline-block;
  border-radius: 4px;
  padding: 7px 15px 8px;
  cursor: pointer;
  background-color: #f2f4f9;
}

.search-box-top .search-right-side .submit:hover {
  background-color: #5d91d9;
  color: #fff;
}

.search-box .search-box-bottom {
  padding: 20px;
}

.search-box .post-tabs {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto;
  grid-template-rows: auto;
}

.search-box .post-tabs span {
  display: inline-block;
  border-radius: 4px;
  padding: 7px 15px 8px;
  cursor: pointer;
}

.search-box .post-tabs span:hover:not(.active) {
  background-color: #f2f4f9;
}

.search-box .post-tabs .active {
  background-color: #5d91d9;
  color: #fff;
}

@media screen and (max-width: 800px) {
  .search-box .post-tabs {
    grid-template-columns: auto auto auto auto auto auto;
    grid-template-rows: auto auto;
    margin: -5px 0;
  }
  .search-box .post-tabs li {
    padding: 5px 0;
  }
}

@media screen and (max-width: 500px) {
  .search-box .post-tabs {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
  }
}

/* DARKMODE  */

body.darkmode .search-box {
  background-color: #0e1625;
  border: 1px solid var(--dark-boder-color);
}

body.darkmode .search-box .search-box-top {
  border-bottom: 1px solid var(--dark-boder-color);
}

body.darkmode .search-box-top .textfield-container input {
  color: #fff;
}

/* MARKDOWN EDITOR */
.search-box .mde-header {
  background-color: var(--light-bg-color);
  color: var(--light-text-color);
  color: inherit;
}
body.darkmode .search-box .mde-header {
  background-color: var(--dark-bg-color);
  color: var(--dark-text-color);
  color: inherit;
  transition: background-color 0.2s color 0.2s;
}
.react-mde {
  border: none !important;
}

.mde-header button {
  color: inherit;
}

.mde-header.markdown-not-editing {
  display: none;
}
.mde-header {
  display: flex;
}
.mde-header ul.mde-header-group li.mde-header-item button {
  color: inherit !important;
}
.edit-toggle {
  position: absolute;
  top: 6px;
  right: 8px;
  font-size: 1.2rem;
  cursor: pointer;
}

/* FOCUS AND HOVER EFFECTS */
body.darkmode .search-container .tabs span:hover:not(.active),
body.darkmode .search-container .tabs span:focus {
  background-color: #17243c;
}

body .search-container .tabs span.active,
body .search-container .tabs span.active:focus {
  background-color: #5d91d9;
  color: #fff;
}

body.lightmode .search-box .post-tabs span:focus,
body.lightmode .search-container .tabs span:focus {
  background-color: #f2f4f9;
}

body.darkmode .search-box .post-tabs span:hover:not(.active),
body.darkmode .search-box .post-tabs span:focus:not(.active) {
  background-color: #17243c;
}
