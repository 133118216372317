/* @import url("https://fonts.googleapis.com/css2?family=Karma:wght@400;700&family=Poppins:ital,wght@0,400;0,600;1,400&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Karma:wght@400;700&family=Poppins:ital,wght@0,400;0,600;1,400&family=Titillium+Web:wght@900&display=swap");
:root {
  --light-bg-color: #f2f4f9;
  --light-boder-color: #dbdfea;
  --light-title-color: #3b3b3f;
  --light-text-color: #828a95;
  --light-link-color: #5d91d9;
  --light-link-hover-color: #3480e9;

  --dark-bg-color: #0e182a;
  --dark-boder-color: rgb(47, 61, 86);
  --dark-titel-color: #ffff;
  --dark-text-color: rgb(255, 255, 255, 0.6);
  --dark-link-color: #5d91d9;
  --dark-link-hover-color: #3480e9;
}

* {
  box-sizing: border-box;
}
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  padding: 0;
  margin: 0;
  background-color: var(--light-bg-color);
  vertical-align: middle;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: var(--light-text-color);
  transition: background-color 0.2s, color 0.2s;
  background-image: url("./assets/stars-background.svg");
}
body.darkmode {
  background-color: var(--dark-bg-color);
  color: var(--dark-text-color);
  transition: background-color 0.2s, color 0.2s;
}
div {
  word-wrap: break-word;
  -webkit-text-size-adjust: 100%;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Titillium Web", serif;
  font-family: "Karma", serif;
  text-rendering: optimizelegibility;
  text-decoration: none;
  margin: 0px;
}
a {
  color: var(--light-link-color);
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  color: var(--light-link-hover-color);
  outline: 0;
  text-decoration: none;
  cursor: pointer;
}
body.darkmode a {
  color: var(--dark-link-color);
}
body.darkmode a:hover {
  color: var(--dark-link-hover-color);
}
img,
a img {
  border: 0;
  vertical-align: middle;
}
ol.no-styling,
ul.no-styling {
  margin: 0;
  padding: 0;
  list-style: none;
}
textarea,
input {
  font-family: "Poppins", sans-serif;
}
::placeholder {
  color: #95a1b0 !important;
  font-weight: 300;
  opacity: 1;
}
body.darkmode ::placeholder {
  color: #535a63 !important;
  font-weight: 300;
  opacity: 1;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: transparent;
  transition: background-color 5000s ease-in-out 0s;
}
body.darkmode input:-webkit-autofill,
body.darkmode input:-webkit-autofill:hover,
body.darkmode input:-webkit-autofill:focus,
body.darkmode textarea:-webkit-autofill,
body.darkmode textarea:-webkit-autofill:hover,
body.darkmode textarea:-webkit-autofill:focus,
body.darkmode select:-webkit-autofill,
body.darkmode select:-webkit-autofill:hover,
body.darkmode select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
}
label {
  cursor: pointer;
}
.relative {
  position: relative;
}
.position-left {
  margin-right: 7px;
}
.position-right {
  margin-left: 7px;
}
.hidden {
  display: none !important;
}
.wrapper {
  width: 100%;
  max-width: 800px;
  padding: 0 15px;
  margin: 0 auto;
}
#main {
  position: relative;
}
.logo-wrapper.dark:hover {
  color: #000;
}
.logo-wrapper.light:hover {
  color: #fff;
}
