.hero {
  position: relative;
  height: 240px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.hero .illustration {
  position: absolute;
  bottom: -134px;
  right: -25px;
  z-index: -1;
}

.hero .illustration img {
  width: 400px;
}
.hero .hero-content {
  margin-bottom: -50px;
}
.hero .logo {
  font-family: "Titillium Web", serif;
  font-size: 70px;
  color: var(--light-title-color);
  font-weight: 700;
  /* text-shadow: 1px 0px 0px var(--light-title-color), -1px 0px 0px var(--light-title-color), 0px 1px 0px var(--light-title-color), 0px -1px 0px var(--light-title-color); */
  margin-bottom: -21px;
  transition: color 0.2s;
}
body.darkmode .hero .logo {
  color: var(--dark-titel-color);
  text-shadow: 1px 1px 0px #515d6d, -1px -1px 0px #515d6d;
  transition: color 0.2s;
}

@media screen and (max-width: 700px) {
  .hero {
    height: 160px;
  }
  .hero .hero-content {
    margin-bottom: 0px;
  }
  .hero .illustration {
    bottom: -124px;
    right: 9px;
  }
  .hero .illustration img {
    max-width: 300px;
  }
}
@media screen and (max-width: 600px) {
  .hero .illustration {
    bottom: -104px;
    right: 2px;
  }
  .hero .illustration img {
    max-width: 240px;
  }
}
@media screen and (max-width: 500px) {
  .hero .illustration {
    display: none;
  }
}

/** DARKMODE **/

body.darkmode .hero .logo {
  color: var(--dark-titel-color);
  text-shadow: 1px 1px 0px #515d6d, -1px -1px 0px #515d6d;
}
