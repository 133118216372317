#result-box {
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #fff;
  border: 1px solid #dbdfea;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  padding: 20px;
  color: #696f77;
}
body.darkmode #result-box {
  background-color: #0e1625;
  border: 1px solid var(--dark-boder-color);
}
#result-box .expandable {
  color: #5d91d9;
  cursor: pointer;
}
#result-box .expandable::after {
  content: "\f0d7";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 14px;
  margin-left: 7px;
}
#result-box .expandable.open::after {
  content: "\f0d8";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 14px;
  margin-left: 7px;
}
#result-box ul ul {
  padding-left: 30px;
  padding: 0px 0px 5px 30px;
}
#result-box .text-light {
  color: #9ea8b5;
  margin-left: 0.3rem;
}
#result-box .post-type {
  color: #d95d69;
}

#result-box pre {
  white-space: pre-line;
}

.result-container.troll {
  animation: spin 3s linear 5;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
